
<template>
  <div class="base-arrow">
    <svg id="arrow" class="arrow" viewBox="0 0 20 18">
      <g id="line" class="line">
        <line class="cls-1" x1="1" y1="9" x2="13.93" y2="9"/>
      </g>
      <g id="chevron" class="chevron">
        <polyline class="cls-2" points="10.06 1 16.06 9 10.06 17"/>
      </g>
    </svg>
  </div>
</template>

<script>

import gsap from 'gsap/src';

export default {
  name: 'BaseArrow',

  mounted () {

    gsap.set(this.$el.getElementsByClassName('line').line, {
      scaleX:0,
      x: -6,
      stroke: this.$props.color,
      transformOrigin: 'right center'
    });
    gsap.set(this.$el.getElementsByClassName('chevron').chevron, {
      x: -4,
      stroke: this.$props.color,
    });

  },
  props: [
    'color',
    'hoverColor'
  ],
  methods: {

    handleOver () {
      gsap.to(this.$el.getElementsByClassName('line').line, {
        duration: .15,
        stroke: this.$props.hoverColor,
        x: 0,
        scaleX:1,

      });

      gsap.to(this.$el.getElementsByClassName('chevron').chevron, {
        duration: .15,
        stroke: this.$props.hoverColor,
        x: 0,
      });
    },

    handleOut () {
      gsap.to(this.$el.getElementsByClassName('line').line, {
        duration: .15,
        stroke: this.$props.color,
        x: -4,
        scaleX:0
      });

      gsap.to(this.$el.getElementsByClassName('chevron').chevron, {
        duration: .15,
        stroke: this.$props.color,
        x: -4
      });
    }
  }
};
</script>


<style scoped>

.arrow {
  pointer-events: all;
}

.cls-1 {
  stroke-miterlimit: 10;
}

.cls-1, .cls-2 {
  fill: none;
  stroke-linecap: round;
  stroke-width: 2.5px;
}

.cls-2 {
  stroke-linejoin: round;
}

.base-arrow {
  width: 15px;
  height: 15px;

}

.line {
  stroke:#000000;
}

.chevron {
  stroke: #000000;
}

</style>